import { Box, Link, Paper, Stack, Typography } from "components";
import classnames from "classnames";
import PropTypes from "prop-types";

function Item({ children, chip, className, variant, ...props }) {
  return (
    <Box className="BupItem-root" hidden={props.hidden}>
      <Paper>
        <Stack
          alignItems="center"
          flexDirection="row"
          justifyContent="space-between"
          useFlexGap
        >
          {props.title && (
            <Typography variant={variant || "h4"}>
              {props.title}
              {chip}
            </Typography>
          )}
          {props.link && (
            <Link className="BupItem-link" to={props.to}>
              {props.link}
            </Link>
          )}
        </Stack>
        {props.span && <Typography variant="p">{props.span}</Typography>}
        <Box className={classnames("BupItem-content", className)} sx={props.sx}>
          {children}
        </Box>
      </Paper>
    </Box>
  );
}

Item.propTypes = {
  children: PropTypes.any,
  className: PropTypes.string,
  hidden: PropTypes.bool,
  link: PropTypes.string,
  title: PropTypes.string.isRequired,
  sx: PropTypes.object,
  to: PropTypes.string,
  variant: PropTypes.string,
};

export default Item;
