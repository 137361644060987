import DATE from "./datetime.json";

const APP = {
  alias: "BondUP",
  company: "Social Up",
  networkUrl: "https://app.bondup.cl/",
  slogan: "Los 60 los nuevos 40",
  websiteUrl: "https://bondup.cl",
};

const DEVICE = {
  xl: 1280,
  lg: 1024,
  md: 768,
  sm: 640,
  xs: 425,
};

const FORM = {
  order: {
    asc: "ASC",
    desc: "DESC",
  },
  view: ["view_headline", "view_stream", "view_comfy"],
};

const GSAP = {
  polygon: {
    b0: "polygon(0% 100%, 100% 100%, 100% 100%, 0% 100%)",
    b1: "polygon(0% 100%, 100% 100%, 100% 0%, 0% 0%)",
    t0: "polygon(0% 0%, 100% 0%, 100% 0%, 0% 0%)",
    t1: "polygon(0% 0%, 100% 0%, 100% 100%, 0% 100%)",
  },
};

const PAGES = [
  "/",
  "/contacto/",
  "/empresas/",
  "/nosotros/",
  "/panoramas/",
  "/precios/",
];

export { APP, DATE, DEVICE, FORM, GSAP, PAGES };
