import classnames from "classnames";
import PropTypes from "prop-types";

function BupVideo({ className, src, reel, title }) {
  if (!src) return null;

  return (
    <iframe
      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
      allowFullScreen
      className={classnames("BupVideo-root", className, {
        "BupVideo-reel": reel,
      })}
      height="631"
      src={src}
      title={title}
      width="1009"
    />
  );
}

BupVideo.propTypes = {
  className: PropTypes.string,
  src: PropTypes.string,
  title: PropTypes.string,
  reel: PropTypes.bool,
};

export default BupVideo;
