"use client";

import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Slide from "@mui/material/Slide";
import { Button } from "components";
import { forwardRef } from "react";

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

function BupDialog({ alert, open, handleClose, handleConfirm, title }) {
  const handleAccept = () => {
    handleConfirm();
    handleClose();
  };

  return (
    <Dialog
      aria-describedby="alert-dialog"
      className="Bup-dialog"
      keepMounted
      onClose={handleClose}
      open={open}
      TransitionComponent={Transition}
    >
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog">{alert}</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button color="inherit" onClick={handleClose}>
          Cancelar
        </Button>
        <Button color="primary" onClick={handleAccept}>
          Aceptar
        </Button>
      </DialogActions>
    </Dialog>
  );
}

BupDialog.defaultProps = {
  title: "Confirmación",
};

export default BupDialog;
