"use client";

import classnames from "classnames";
import PropTypes from "prop-types";

function Select({ className, helper, options, ...props }) {
  return (
    <select className={classnames("BupForm-select", className)} {...props}>
      {typeof helper === "string" && (
        <option value="" disabled>
          {helper}
        </option>
      )}
      {options?.map((option, i) => (
        <option key={i} value={Array.isArray(option) ? option[0] : option}>
          {Array.isArray(option) ? option[1] : option}
        </option>
      ))}
    </select>
  );
}

Select.propTypes = {
  className: PropTypes.string,
  helper: PropTypes.string,
  options: PropTypes.oneOfType([PropTypes.array, PropTypes.string]).isRequired,
};

export default Select;
