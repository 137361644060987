import classnames from "classnames";
import { usePathname, useRouter } from "next/navigation";
import PropTypes from "prop-types";

function BupLink({ className, disabled, navLink, params = {}, to, ...props }) {
  const pathname = usePathname();
  const router = useRouter();

  const handleClick = (e) => {
    e.preventDefault();
    router.push(to);
    window.scrollTo(0, 0);
  };

  if (!!to) {
    return (
      <button
        className={classnames("BupLink-root BupLink-nav", className, {
          "Bup-active": navLink && pathname.includes(to),
          "Bup-disabled": disabled,
        })}
        id={props.id}
        onClick={handleClick}
      >
        {props.children}
      </button>
    );
  }

  return (
    <a
      className={classnames("BupLink-root BupLink-href", className, {
        "Bup-disabled": disabled,
      })}
      id={props.id}
      href={props.href}
      onClick={props.onClick}
      target={props.target || "_blank"}
    >
      {props.children}
    </a>
  );
}

BupLink.propTypes = {
  className: PropTypes.string,
  children: PropTypes.any.isRequired,
  disabled: PropTypes.bool,
  href: PropTypes.string,
  onClick: PropTypes.func,
  params: PropTypes.object,
  target: PropTypes.string,
  to: PropTypes.string,
};

export default BupLink;
