"use client";

import Backdrop from "@mui/material/Backdrop";
import Modal from "@mui/material/Modal";
import Paper from "@mui/material/Paper";
import { Box, IconButton, Typography } from "components";
import classnames from "classnames";
import { useTrigger } from "hooks";
import PropTypes from "prop-types";
import { useEffect } from "react";
import { helper, str } from "utils";

function BupModal({
  children,
  fullWidth,
  open,
  onClose,
  size,
  title,
  ...props
}) {
  useTrigger("keydown", (e) => {
    if (open && e.key === "Escape") {
      helper.updateClassList("Bup-modal", false);
      onClose();
    }
  });

  useEffect(() => {
    if (open) helper.updateClassList("Bup-modal", true);
    else helper.updateClassList("Bup-modal", false);
  }, [open]);

  return (
    <Modal
      className="BupModal-root"
      closeAfterTransition
      onClose={onClose}
      open={open}
      slots={{ backdrop: Backdrop }}
      slotProps={{ backdrop: { timeout: 500 } }}
      {...props}
    >
      <Paper
        className={classnames("BupModal-paper", {
          [`BupModal-size${str.capitalize(size)}`]: size,
          "BupModal-fullWidth": fullWidth,
        })}
      >
        {title && (
          <Box className="BupModal-title">
            <Typography component="h2" variant="h6">
              {title}
            </Typography>
            <IconButton icon="close" onClick={onClose} />
          </Box>
        )}
        {children}
      </Paper>
    </Modal>
  );
}

BupModal.propTypes = {
  children: PropTypes.node.isRequired,
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  ...Modal.propTypes,
};

export default BupModal;
