import classnames from "classnames";
import PropTypes from "prop-types";

function BupIcon({ name, variant = "io", ...props }) {
  if (props.hidden) return null;

  return (
    <i
      id={props.id}
      className={classnames("BupIcon-root", props.className, {
        "material-icons": variant === "io",
        [`fa-brands fa-${name}`]: variant === "fb",
        [`fa-solid fa-${name}`]: variant === "fs",
        [`fa-regular fa-${name}`]: variant === "fr",
        [`fa-light fa-${name}`]: variant === "fl",
        "Bup-circle": props.circle,
        "Bup-square": props.square,
      })}
    >
      {variant === "io" && name}
    </i>
  );
}

BupIcon.propTypes = {
  className: PropTypes.string,
  circle: PropTypes.bool,
  hidden: PropTypes.bool,
  id: PropTypes.string,
  name: PropTypes.string.isRequired,
  square: PropTypes.bool,
  variant: PropTypes.string,
};

export default BupIcon;
