import { APP, DATE } from "data/consts";
import ReactHtmlParser from "react-html-parser";
import { str } from "utils";
import moment from "moment";

/**
 * Add a specified number of hours to a given date.
 *
 * @param {Date} date - The date to which hours will be added.
 * @param {number} hours - The number of hours to add.
 * @returns {Date} A new Date object with the added hours.
 */
function addHoursToDate(date, hours) {
  const millisecondsPerHour = 3600000; // 1000 ms * 60 sec * 60 min
  const totalMillisecondsToAdd = hours * millisecondsPerHour;
  return new Date(date.getTime() + totalMillisecondsToAdd);
}

/**
 * Calculate age based on a given birthday date.
 *
 * @param {string} birthday - The birthday date in string format (e.g., "YYYY-MM-DD").
 * @returns {number} The calculated age in years.
 */
function calculateAge(birthday) {
  const ageDifMs = Date.now() - new Date(birthday).getTime();
  const ageDate = new Date(ageDifMs);
  return Math.abs(ageDate.getUTCFullYear() - 1970);
}

/**
 * Convert a calendar date to a specific date format.
 *
 * @param {object} date - The calendar date object (e.g., from a library like moment.js).
 * @returns {string} The formatted date string (e.g., "YYYY-MM-DD").
 */
function calendarToDate(date) {
  return moment(date.$d).format("YYYY-MM-DD");
}

/**
 * Convert a calendar date to a specific time format.
 *
 * @param {object} date - The calendar date object (e.g., from a library like moment.js).
 * @returns {string} The formatted time string (e.g., "HH:mm").
 */
function calendarToTime(date) {
  return moment(date.$d).format("HH:mm");
}

/**
 * Format a birthday date string with optional inclusion of the year.
 *
 * @param {string} dateString - The birthday date string in "YYYY-MM-DD" format.
 * @param {boolean} includeYear - Whether to include the year in the formatted birthday.
 * @returns {string} The formatted birthday string.
 */
function formatBirthday(dateString, includeYear = false) {
  if (!dateString) {
    return null;
  }

  const date = moment(dateString, "YYYY-MM-DD");
  const day = date.format("D");
  const month = DATE.monthTranslations[date.format("MMMM")];
  const year = includeYear ? date.format("YYYY") : "";

  if (includeYear) {
    return `${day} de ${month} de ${year}`;
  } else {
    return `${day} de ${month}`;
  }
}

/**
 * Format a date and time string, optionally converting a timestamp to a localized date and time format.
 *
 * @param {string} dateString - The date and time string to format.
 * @param {boolean} isTimestamp - Whether the input string is a timestamp.
 * @returns {string} The formatted date and time string (e.g., "1 de January, 15:30 hrs.").
 */
function formatDateTime(dateString, isTimestamp = false) {
  if (!isValidDate(dateString)) {
    return "";
  }

  const date = toLocaleDate(dateString, isTimestamp);
  const day = date.format("D");
  const month = DATE.monthTranslations[date.format("MMMM")];
  const time = date.format("HH:mm");

  return `${day} de ${month}, ${time} hrs.`;
}

/**
 * Format a full date string with optional inclusion of time.
 *
 * @param {string} dateString - The date string to format.
 * @param {boolean} includeTime - Whether to include the time in the formatted date.
 * @returns {string} The formatted full date string (e.g., "Sun 1 de January, 2023" or "Sun 1 de January, 2023 a las 15:30 hrs.").
 */
function formatFullDate(dateString, includeTime = false) {
  const date = toLocaleDate(dateString);
  const dayOfWeek = DATE.daysTranslations[date.format("dddd")].slice(0, 3);
  const dayOfMonth = date.format("D");
  const month = DATE.monthTranslations[date.format("MMMM")];
  const year = date.format("YYYY");
  const time = includeTime ? ` a las ${date.format("HH:mm")} hrs.` : "";

  return `${str.capitalize(
    dayOfWeek
  )} ${dayOfMonth} de ${month}, ${year}${time}`;
}

/**
 * Format a date string with day of week, day of month, and month.
 *
 * @param {string} dateString - The date string to format.
 * @returns {string} The formatted day of week string (e.g., "Domingo, 1 de Enero").
 */
function formatDayOfWeek(dateString) {
  const date = toLocaleDate(dateString);
  const dayOfWeek = DATE.daysTranslations[date.format("dddd")];
  const dayOfMonth = date.format("DD");
  const month = DATE.monthTranslations[date.format("MMMM")];
  return `${dayOfWeek}, ${dayOfMonth} de ${month}`;
}

/**
 * Format a short date string with day, month, year, and time.
 *
 * @param {string} dateString - The date string to format.
 * @returns {string} The formatted short date string (e.g., "Sun 1 January 2023, 15:30 hrs").
 */
function formatShortDate(dateString) {
  const date = toLocaleDate(dateString);
  const dayOfWeek = DATE.daysTranslations[date.format("dddd")].slice(0, 3);
  const dayOfMonth = date.format("D");
  const month = DATE.monthTranslations[date.format("MMMM")];
  const year = date.format("YYYY");
  const time = `${date.format("HH:mm")} hrs`;

  return `${str.capitalize(dayOfWeek)} ${dayOfMonth} ${month} ${year}, ${time}`;
}

/**
 * Format a date string to display month and year.
 *
 * @param {string} dateString - The date string to format.
 * @returns {string} The formatted month and year string (e.g., "January 2023").
 */
function formatMonthYear(dateString) {
  const date = moment(dateString);
  const month = DATE.monthTranslations[date.format("MMMM")];
  const year = date.format("YYYY");

  return `${month} de ${year}`;
}

/**
 * Get the astrological sign based on a date string.
 *
 * @param {string} dateString - The date string to determine the astrological sign for.
 * @returns {string} The astrological sign for the given date (e.g., "Aries").
 */
function getAstrological(dateString) {
  const parsedDate = moment(dateString);
  const timestamp = moment(`2023/${parsedDate.format("MM/DD")}`).valueOf();
  const calendar = DATE.astrologicalDate.map((day) =>
    moment(`2023/${day}`).valueOf()
  );
  const index = calendar.findIndex((limit, i) => timestamp <= limit);

  return DATE.astrologicalSign[index > 0 ? index : 0];
}

/**
 * Get an array of calendar days for the current month.
 *
 * @returns {Array} An array containing the current month and an array of calendar days in "YYYY-MM-DD" format.
 */
function getCalendarDays() {
  const firstDayOfMonth = moment().startOf("month");
  const startDate = firstDayOfMonth.startOf("week");
  const endDate = moment(startDate).add(5, "weeks").endOf("week");

  const calendarDays = [];
  let currentDate = startDate;

  while (currentDate.isSameOrBefore(endDate)) {
    calendarDays.push(currentDate.format("YYYY-MM-DD"));
    currentDate.add(1, "day");
  }

  return [moment().format("YYYY-MM"), calendarDays];
}

/**
 * Get the localized time (HH:mm) from a date string.
 *
 * @param {string} dateString - The date string to convert to localized time.
 * @returns {string} The localized time in "HH:mm" format.
 */
function getLocaleTime(dateString) {
  const dateTime = toLocaleDate(dateString);
  return dateTime.format("HH:mm");
}

/**
 * Get the difference in hours between a given date and the current date.
 *
 * @param {string} date - The date string to compare.
 * @returns {number} The difference in hours.
 */
function getDiffHours(date) {
  const diffTime = Math.abs(new Date() - new Date(date));
  return Math.ceil(diffTime / (1000 * 60 * 60));
}

/**
 * Check if an event is a streaming event.
 *
 * @param {object} event - The event object to check.
 * @returns {boolean} `true` if the event is a streaming event; otherwise, `false`.
 */
function isStreamingEvent(event) {
  const now = Date.now();
  const m30 = DATE.dayInMilliseconds / 48; // Half-hour in milliseconds

  return Boolean(
    event.meetUrl &&
      isUpcomingEvent(event) &&
      toLocaleDate(event.startDate).valueOf() < now + m30
  );
}

/**
 * Check if an event is an upcoming event.
 *
 * @param {object} event - The event object to check.
 * @returns {boolean} `true` if the event is upcoming; otherwise, `false`.
 */
function isUpcomingEvent(event) {
  if (!event?.endDate) return false;
  const endDate = toLocaleDate(event.endDate);
  return !endDate.isBefore(moment());
}

/**
 * Check if a date string is valid.
 *
 * @param {string} dateString - The date string to validate.
 * @returns {boolean} `true` if the date string is valid; otherwise, `false`.
 */
function isValidDate(dateString) {
  const date = moment(dateString, "YYYY-MM-DDTHH:mm:ss.SSSZ");
  return date.isValid();
}

/**
 * Convert a date string to a moment.js date object.
 *
 * @param {string} dateString - The date string to convert.
 * @param {boolean} isTimestamp - Set to `true` if `dateString` is a timestamp.
 * @returns {object} A moment.js date object.
 */
function toLocaleDate(dateString, isTimestamp = false) {
  if (typeof dateString !== "string" || isTimestamp) return moment(dateString);
  return moment(dateString?.replaceAll("Z", ""));
}

/**
 * Convert date and time strings to a database-compatible date-time format.
 *
 * @param {string} date - The date string in "YYYY-MM-DD" format.
 * @param {string} time - The time string in "HH:mm" format.
 * @returns {object} A JavaScript date object with combined date and time.
 */
function toDBDateTime(date, time) {
  return moment(date + "T" + calendarToTime(time) + "Z")._d;
}

/**
 * Convert a date string to a short time format (e.g., "5h" or "30m ago").
 *
 * @param {string} dateString - The date string to convert.
 * @param {boolean} useLongFormat - Set to `true` to use long format (e.g., "5 hours ago").
 * @param {boolean} isTimestamp - Set to `true` if `dateString` is a timestamp.
 * @returns {string} The short time format string.
 */
function toShortTime(dateString, useLongFormat = false, isTimestamp = false) {
  const secondsAgo = (Date.now() - new Date(dateString).getTime()) / 1000;

  const timeUnits = [
    { unit: "año", short: "y", base: 31536000 },
    { unit: "semana", short: "w", base: 604800 },
    { unit: "día", short: "d", base: 86400 },
    { unit: "hora", short: "h", base: 3600 },
    { unit: "minuto", short: "m", base: 60 },
    { unit: "segundo", short: "s", base: 1 },
  ];

  for (let i = 0; i < timeUnits.length; i++) {
    const unit = timeUnits[i];
    const floor = Math.floor(secondsAgo / unit.base);

    if (floor > 0) {
      return useLongFormat
        ? str.pluralize(floor, unit.unit)
        : `${floor}${unit.short}`;
    }
  }

  return "0s";
}

function toReactElement(result) {
  if (!result) return "";
  return ReactHtmlParser(
    result
      .replace(/\[BUP\]/g, `<strong>${APP.alias}</strong>`)
      .replace(/\[SUP\]/g, `<strong>${APP.company}</strong>`)
      .replace(/\[EMAIL\]/g, "<q>contacto@bondup.cl</q>")
  );
}

const parser = {
  addHoursToDate,
  calculateAge,
  calendarToDate,
  calendarToTime,
  formatBirthday,
  formatDateTime,
  formatFullDate,
  formatDayOfWeek,
  formatMonthYear,
  formatShortDate,
  getAstrological,
  getCalendarDays,
  getDiffHours,
  getLocaleTime,
  isStreamingEvent,
  isUpcomingEvent,
  isValidDate,
  toDBDateTime,
  toLocaleDate,
  toShortTime,
  toReactElement,
};

export default parser;
