"use client";

import { IconButton } from "components";
import PropTypes from "prop-types";

function File({ disabled, loading, icon, id, input, ...props }) {
  return (
    !props.hidden && (
      <>
        {icon ? (
          <IconButton
            className={props.className}
            disabled={disabled}
            icon={icon}
            loading={loading}
            onClick={() => input.current.click()}
          />
        ) : (
          <a className={props.className} onClick={() => input.current.click()}>
            {props.label}
          </a>
        )}
        <input
          className="Bup-hidden"
          id={id}
          onChange={(e) => props.setFile(e.target.files?.[0])}
          ref={input}
          type="file"
        />
      </>
    )
  );
}

File.propTypes = {
  className: PropTypes.string,
  disabled: PropTypes.bool,
  icon: PropTypes.string,
  id: PropTypes.string,
  setFile: PropTypes.func.isRequired,
  label: PropTypes.string,
  hidden: PropTypes.bool,
  loading: PropTypes.bool,
};

export default File;
