"use client";

import { useState } from "react";

function useEventOptions() {
  const [anchorEl, setAnchorEl] = useState(false);

  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const toggleMenu = (event) => {
    setAnchorEl(open ? null : event.currentTarget);
  };

  return {
    anchorEl,
    handleClose,
    handleClick,
    toggleMenu,
    open,
  };
}

export default useEventOptions;
