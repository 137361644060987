"use client";

import { Icon } from "components";
import classnames from "classnames";
import PropTypes from "prop-types";
import { useState } from "react";

function Input({ className, datalist, name, placeholder, textarea, ...props }) {
  const [visible, setVisible] = useState(false);

  const id = datalist ? `${name}-list` : null;
  const type = !visible ? props.type : "text";

  return (
    <>
      {!textarea ? (
        <input
          {...props}
          className={classnames("BupForm-input", className)}
          list={id}
          placeholder={placeholder + (props.optional ? " (opcional)" : "")}
          optional={props.optional?.toString()}
          type={type}
        />
      ) : (
        <textarea
          {...props}
          className={classnames("BupForm-textarea", className)}
          placeholder={placeholder}
          type={type}
        />
      )}
      {props.type === "password" && (
        <span className="BupForm-password" onClick={() => setVisible(!visible)}>
          <Icon
            className="BupForm-eye"
            name={`eye${visible ? "-slash" : ""}`}
            variant="fs"
          />
        </span>
      )}
      {Array.isArray(datalist) && datalist?.length > 0 && (
        <datalist id={id}>
          {datalist.map((option, i) => (
            <option key={i} value={Array.isArray(option) ? option[0] : option}>
              {Array.isArray(option) ? option[1] : option}
            </option>
          ))}
        </datalist>
      )}
    </>
  );
}

Input.propTypes = {
  className: PropTypes.string,
  datalist: PropTypes.array,
  name: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  textarea: PropTypes.bool,
  type: PropTypes.string,
};

export default Input;
