import { FORM } from "data/consts";

/**
 * Add an element to an array if it doesn't already exist.
 *
 * @param {Array} array - The original array.
 * @param {any} element - The element to add to the array.
 * @returns {Array} A new array with the element added if it didn't exist in the original array.
 */
function addElement(array, element) {
  // Create a copy of the original array (or an empty array if it's null or undefined)
  const copy = array ? [...array] : [];

  // Check if the element doesn't already exist in the array
  if (!array.includes(element)) {
    // Add the element to the copy of the array
    copy.push(element);
  }

  return copy;
}

/**
 * Clone an array and add specified values to it if they don't already exist.
 *
 * @param {Array} array - The original array to clone.
 * @param {...any} values - Values to add to the cloned array.
 * @returns {Array} A new array with specified values added if they didn't exist in the original array.
 */
function cloneArray(array, ...values) {
  // Return an empty array if the original array is null or undefined
  if (!array) return [];

  // Create a copy of the original array
  const copy = [...array];

  // Iterate through the provided values and add them to the copy if they don't exist
  values?.forEach((element) => {
    if (!array?.includes(element)) {
      copy.push(element);
    }
  });

  return copy;
}

/**
 * Concatenate multiple arrays into a single array while filtering out undefined and empty values.
 *
 * @param {...Array} arrays - Arrays to concatenate.
 * @returns {Array} A new concatenated array with undefined and empty values filtered out.
 */
function concatArray(...arrays) {
  // Use the spread operator to concatenate the arrays and filter out undefined and empty values
  return []
    .concat(...arrays)
    .filter((element) => element !== undefined && element !== "");
}

/**
 * Count the number of common elements between two arrays.
 *
 * @param {Array} arr1 - The first array.
 * @param {Array} arr2 - The second array.
 * @returns {number} The count of common elements between the two arrays.
 */
function countCommon(arr1 = [], arr2 = []) {
  let common = 0;

  // Check if both arrays have elements
  if (arr1?.length && arr2?.length) {
    // Iterate through the first array and count common elements in the second array
    for (let i = 0; i < arr1.length; i++) {
      if (arr2.includes(arr1[i])) {
        common++;
      }
    }
  }

  return common;
}

/**
 * Create a dictionary (object) from an array of objects.
 *
 * @param {Array} array - The array of objects.
 * @param {string|boolean} a - The key property to use in the dictionary or `true` to use 'id'.
 * @param {string|boolean} b - The value property to use in the dictionary or `true` to use the rest of the object.
 * @returns {Object} A dictionary object created from the array.
 */
function dictionary(array, a, b) {
  return Object.fromEntries(
    a && b
      ? array.map((x) => [x[a], x[b]])
      : array.map(({ id, ...data }) => [
          a === true ? id.toUpperCase() : id,
          data,
        ])
  );
}

/**
 * Generate an array of numbers with the specified size.
 *
 * @param {number} size - The size (length) of the array.
 * @returns {Array} An array of numbers from 0 to size - 1.
 */
function generateArray(size) {
  return [...Array(size).keys()];
}

/**
 * Get an array of IDs from an array of instances (objects with '_id' property).
 *
 * @param {Array} instances - The array of instances.
 * @returns {Array} An array of IDs extracted from the instances.
 */
function getIds(instances) {
  return instances?.map((element) => element._id) || [];
}

/**
 * Get an array of options (key-value pairs) from an object and sort them by key.
 *
 * @param {Object} obj - The object to extract options from.
 * @returns {Array} An array of sorted key-value pairs from the object.
 */
function getOptions(obj) {
  if (!obj) return [];
  return Object.entries(obj).sort((a, b) => sortBy(a, b, 1));
}

/**
 * Check if two arrays have common elements.
 *
 * @param {Array} arr1 - The first array.
 * @param {Array} arr2 - The second array.
 * @returns {boolean} `true` if the arrays have common elements, otherwise `false`.
 */
function hasCommon(arr1 = [], arr2 = []) {
  let common = false;

  // Check if both arrays have elements
  if (arr1?.length && arr2?.length) {
    // Iterate through the first array and check for common elements in the second array
    for (let i = 0; i < arr1.length; i++) {
      if (arr2.includes(arr1[i])) {
        common = true;
        break;
      }
    }
  }

  return common;
}

/**
 * Check if an object of errors contains any true values.
 *
 * @param {Object} errors - An object where keys represent error fields and values indicate error status.
 * @returns {boolean} `true` if any error value is true, indicating the presence of errors.
 */
function hasErrors(errors) {
  return Object.values(errors).some((error) => error === true);
}

/**
 * Join elements of an array with commas and "and" for the last element.
 *
 * @param {Array} array - The array of elements to join.
 * @returns {string} The joined string with commas and "and" for the last element.
 */
function joinElements(array) {
  if (!array) return;

  if (array.length > 1)
    return `${array.slice(0, -1).join(", ")} and ${array.slice(-1)[0]}`;

  return array[0];
}

/**
 * Merge multiple objects into a single object, omitting null and undefined values.
 *
 * @param {...Object} args - Objects to merge.
 * @returns {Object} A merged object with null and undefined values omitted.
 */
function mergeObject(...args) {
  // Combine multiple objects into one
  const map = Object.assign({}, ...args);

  // Filter out keys with "__typename", null, and undefined values
  return Object.keys(map).reduce(
    (acc, key) =>
      key !== "__typename" && map[key] !== null && map[key] !== undefined
        ? { ...acc, [key]: map[key] }
        : acc,
    {}
  );
}

/**
 * Repeat a React component a specified number of times.
 *
 * @param {React.Component} Component - The React component to repeat.
 * @param {number} times - The number of times to repeat the component.
 * @returns {Array} An array of React components repeated 'times' times.
 */
function repeatComponent(Component, times) {
  return generateArray(times).map((x, i) => <Component key={i} />);
}

/**
 * Remove a specific element from an array.
 *
 * @param {Array} array - The original array.
 * @param {any} element - The element to remove from the array.
 * @returns {Array} A new array with the specified element removed.
 */
function removeElement(array, element) {
  const copy = array ? [...array] : [];

  if (array?.includes(element)) {
    copy.splice(
      copy.findIndex((id) => id === element),
      1
    );
  }

  return copy;
}

/**
 * Replace an element at a specific index in an array.
 *
 * @param {Array} array - The original array.
 * @param {number} index - The index at which to replace the element.
 * @param {any} element - The element to replace with.
 * @returns {Array} A new array with the element at the specified index replaced.
 */
function replaceElement(array, index, element) {
  const res = [...array];
  res.splice(index, 1, element);
  return res;
}

/**
 * Shuffle the elements of an array multiple times.
 *
 * @param {Array} array - The original array.
 * @param {number} times - The number of times to shuffle the array.
 * @returns {Array} A new array with elements shuffled multiple times.
 */
function shuffleArray(array = [], times = 10) {
  let res = [...array];

  for (let i = 0; i < times; i++) {
    res = res.sort(() => 0.5 - Math.random());
  }

  return res;
}

/**
 * Sort two elements based on a specified property and sorting order.
 *
 * @param {any} elemA - The first element to compare.
 * @param {any} elemB - The second element to compare.
 * @param {string} sort - The property by which to sort.
 * @param {string} order - The sorting order ('asc' or 'desc').
 * @returns {number} A comparison result to determine the sorting order.
 */
function sortBy(elemA, elemB, sort, order) {
  return (
    (elemA?.[sort] > elemB?.[sort] ? 1 : -1) *
    (order === FORM.order.desc ? -1 : 1)
  );
}

/**
 * Update an array by adding or removing an element based on its existence.
 *
 * @param {Array} array - The original array.
 * @param {any} element - The element to add or remove from the array.
 * @returns {Array} A new array with the element added if it didn't exist or removed if it existed.
 */
function updateArray(array, element) {
  const copy = [...array];
  const index = copy.findIndex((x) => x === element);

  if (index === -1) copy.push(element);
  else copy.splice(index, 1);

  return copy;
}

function swapElements(arr, index1, index2) {
  const temp = [...arr];
  [temp[index1], temp[index2]] = [temp[index2], temp[index1]];
  return temp;
}

const object = {
  addElement,
  cloneArray,
  concatArray,
  countCommon,
  dictionary,
  generateArray,
  getIds,
  getOptions,
  hasCommon,
  hasErrors,
  joinElements,
  mergeObject,
  removeElement,
  repeatComponent,
  replaceElement,
  shuffleArray,
  sortBy,
  swapElements,
  updateArray,
};

export default object;
