import Chip from "@mui/material/Chip";
import classnames from "classnames";
import { Skeleton } from "components";
import PropTypes from "prop-types";

function BupChip({ className, height, width, ...props }) {
  if (props.hidden) return null;

  return !props.loading ? (
    <Chip className={classnames("Bup-chip", className)} {...props} />
  ) : (
    <Skeleton height={height} width={width} variant="rounded" />
  );
}

BupChip.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node,
  height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  ...Chip.propTypes,
};

export default BupChip;
