function Curve() {
  return (
    <svg
      width="100%"
      height="75"
      viewBox="0 0 500 75"
      preserveAspectRatio="none"
    >
      <path className="curve" d="M0,0 L0,40 Q250,75 500,40 L500,0 Z" />
    </svg>
  );
}

export default Curve;
