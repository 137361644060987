"use client";

import { useCallback, useState, useEffect } from "react";
import { DEVICE } from "data/consts";

function useMedia() {
  const [top, setTop] = useState(0);
  const [scroll, setScroll] = useState(false);
  const [progress, setProgress] = useState(0);
  const [width, setWidth] = useState(0);

  const scrollUp = useCallback(() => {
    const pY = window.scrollY;
    if (pY < top) setScroll(true);
    else if (pY > top) setScroll(false);
    setTop(pY);
  }, [top]);

  function resize() {
    if (typeof window !== "undefined") {
      setWidth(document?.body.clientWidth);
    }
  }

  useEffect(() => {
    scrollUp();
    window.addEventListener("scroll", scrollUp);
    return () => {
      window.removeEventListener("scroll", scrollUp);
    };
  }, [scrollUp]);

  useEffect(() => {
    const height = document?.body.clientHeight;
    const screen = window.innerHeight;

    setProgress(
      Math.round((top / (height - screen)) * window.innerWidth * 1000 || 0) /
        1000
    );
  }, [top]);

  useEffect(() => {
    resize();
    window.addEventListener("resize", resize);
    return () => {
      window.removeEventListener("resize", resize);
    };
  }, []);

  return {
    progress,
    scroll,
    laptop: width <= DEVICE.lg,
    tablet: width <= DEVICE.md,
    mobile: width <= DEVICE.sm,
    top,
    width,
  };
}

export default useMedia;
